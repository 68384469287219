import { IProductSimple } from '../types/product'
import { OrderFilterOption, SalesRepresentative } from '../types/order'
import { CheckoutPhysicalItem, OrderConfirmationDetails, OrderConfirmationLineItem } from '../types/checkout'
import { PhysicalItem } from '../types/cart'
import { getFormattedProductPrice } from './product-utils'

export type Platform = 'Employee' | 'Customer'

export const trackPage = () => {
  window.analytics.page()
}

export const trackClick = (name: string, properties: any, context?: any) => {
  try {
    if (context) {
      window.analytics.track(name, properties, context)
    } else {
      window.analytics.track(name, properties)
    }
  } catch (err) {
    // Prevent error causing issues to other parts of the app
  }
}

export const trackContactEmployee = (employee: SalesRepresentative, channel: 'Phone' | 'Email', currentUrl: string) => {
  // user clicks on contact employee (phone or mail icon) in the support team card
  if (employee) {
    trackClick('Contact Employee', {
      job_position: employee.title,
      channel: channel,
      location: currentUrl,
    })
  }
}

export const trackAddProductToCart = (product: IProductSimple, quantity: number) => {
  // user clicks on add to cart
  if (product) {
    trackClick('Product Added', {
      product_id: product.id,
      sku: product.properties?.sku,
      name: product.properties?.name,
      product_category: product.properties?.productFamily,
      status: product.properties?.statusFlag,
      quantity: quantity,
    })
  }
}

export const trackRemoveProductFromCart = (
  product: PhysicalItem | CheckoutPhysicalItem | OrderConfirmationLineItem,
  source: string,
  productCategory: string
) => {
  // user removes a product from cart
  if (product) {
    trackClick('Product Removed', {
      product_id: product.productId,
      sku: product.sku,
      name: product.name,
      quantity: product.quantity,
      product_category: productCategory,
      source,
    })
  }
}

export const trackSkipInterests = () => {
  // user selects skip interests on the onboarding flow
  trackClick('Skip Interests', {})
}

export const trackContinueInterests = (userId: string, industries: string[]) => {
  // user selects category and continues to the next page of interests
  trackClick('Continue Interests', {
    category_interest: industries.length > 0 ? industries.join(',') : '',
  })
  try {
    window.analytics.identify(userId, {
      category_interest: industries.length > 0 ? industries.join(',') : '',
    })
  } catch (err) {
    // Prevent error causing issues to other parts of the app
  }
}

export const trackSaveInterests = (userId: string, interests: string[]) => {
  // user completes interests onboarding
  trackClick('Save Interests', {
    subcategory_interest: interests.length > 0 ? interests.join(',') : '',
  })
  try {
    window.analytics.identify(userId, {
      subcategory_interest: interests.length > 0 ? interests.join(',') : '',
    })
  } catch (err) {
    // Prevent error causing issues to other parts of the app
  }
}

export const trackContinueToDashboard = () => {
  // user continues to dashboard from the welcome page
  trackClick('Continue to Dashboard', {})
}

export const trackMenuClick = (menu: string) => {
  // user clicks on menu item on the left of the screen
  trackClick('Menu Clicked', {
    menu,
  })
}

export const trackViewCart = (position: string, items: PhysicalItem[]) => {
  // user clicks to view cart from cart drawer/header
  trackClick('View Cart', {
    position,
    products: items,
  })
}

export const trackActionClicked = (action: string) => {
  // user performs specific actions detailed in Miro
  trackClick('Action Clicked', {
    action,
  })
}

export const trackAddAddress = () => {
  // user selects to add address
  trackClick('Add Address', {})
}

export const trackAddAddressConfirmed = () => {
  // user confirms creation of new address
  trackClick('Add Address Confirmed', {})
}

export const trackCheckoutStarted = (items: PhysicalItem[]) => {
  // user starts checkout flow
  trackClick('Checkout Started', {
    products: items,
  })
}

export const trackPurchaseOrderClicked = () => {
  // user clicks to upload purchase order
  trackClick('Purchase Order Clicked', {})
}

export const trackPurchaseOrderUploaded = () => {
  // user uploads purchase order
  trackClick('Purchase Order Uploaded', {})
}

export const trackShippingAddressSelected = () => {
  // user selects shipping address in the checkout flow
  trackClick('Shipping Address Selected', {})
}

export const trackReviewOrder = () => {
  // user continues to review order before purchase
  trackClick('Review Order', {})
}

export const trackOrderCompleted = (order: OrderConfirmationDetails) => {
  // user submits an order request successfully
  trackClick('Order Submitted', {
    order_id: order.id,
    products: order.consignments?.[0]?.lineItems ?? [],
  })
}

export const getPlatform = (isEmployee: boolean): Platform => {
  if (isEmployee) {
    return 'Employee'
  } else {
    return 'Customer'
  }
}

interface FormattedOrderFilters {
  filterSelected: string
  filterValue: string
}

const getStatusFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  if (current.status.length > previous.status.length) {
    const statusFilter = current.status.filter((status) => previous.status.indexOf(status) === -1)
    if (statusFilter) {
      return statusFilter.map((status) => ({
        filterSelected: 'Order Status',
        filterValue: status,
      }))
    }
  }
  return []
}

const getCustomerFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  if (current.customer && current.customer !== previous.customer) {
    return [
      {
        filterSelected: 'Customer',
        filterValue: current.customer,
      },
    ]
  }
  return []
}

const getBusinessUnitFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  if (current.businessUnit && (!previous.businessUnit || current.businessUnit.length > previous.businessUnit.length)) {
    let businessUnitFilter = []
    if (!previous.businessUnit) {
      businessUnitFilter = current.businessUnit
    } else {
      businessUnitFilter = current.businessUnit.filter((businessUnit) => previous.businessUnit?.indexOf(businessUnit) === -1)
    }
    if (businessUnitFilter) {
      return businessUnitFilter.map((businessUnit) => ({
        filterSelected: 'Business Unit',
        filterValue: businessUnit,
      }))
    }
  }
  return []
}
const getOrderDateFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  if (current.orderDate.length > previous.orderDate.length) {
    const dateFilter = current.orderDate.filter((date) => previous.orderDate.indexOf(date) === -1)
    if (dateFilter) {
      return dateFilter.map((date) => {
        let formattedDate = date
        // custom text formatting
        if (date === 'l30days') {
          formattedDate = 'Last 30 days'
        } else if (date === 'l3mos') {
          formattedDate = 'Last 3 months'
        }
        return {
          filterSelected: 'Order Date',
          filterValue: formattedDate.toString(),
        }
      })
    }
  }
  return []
}

const getShipDateFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  if (current.shipDate.length > previous.shipDate.length) {
    const dateFilter = current.shipDate.filter((date) => previous.shipDate.indexOf(date) === -1)
    if (dateFilter) {
      return dateFilter.map((date) => {
        let formattedDate = date
        // custom text formatting
        if (date === 'l30days') {
          formattedDate = 'Last 30 days'
        } else if (date === 'l3mos') {
          formattedDate = 'Last 3 months'
        }
        return {
          filterSelected: 'Ship Date',
          filterValue: formattedDate.toString(),
        }
      })
    }
  }
  return []
}

const getDateRangeFilter = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  const dateRangeFilter = []
  if (current.from && current.from !== previous.from) {
    dateRangeFilter.push({
      filterSelected: 'Order Date',
      filterValue: `${current.from} <= X`,
    })
  }

  if (current.to && current.to !== previous.to) {
    dateRangeFilter.push({
      filterSelected: 'Order Date',
      filterValue: `${current.to} >= X`,
    })
  }
  return dateRangeFilter
}

export const formatOrderFiltersData = (previous: OrderFilterOption, current: OrderFilterOption): FormattedOrderFilters[] => {
  // we're only sending filter events when a new filter is added
  // not when its removed
  return [
    ...getStatusFilter(previous, current),
    ...getCustomerFilter(previous, current),
    ...getBusinessUnitFilter(previous, current),
    ...getOrderDateFilter(previous, current),
    ...getShipDateFilter(previous, current),
    ...getDateRangeFilter(previous, current),
  ]
}

interface UserTraits {
  email: string
  firstName: string
  lastName: string
  displayName: string
  role: string[]
  companyName: string
  companyId: string
}

export const trackUserAccountCreation = (userId: string, userTraits: UserTraits) => {
  window.analytics.identify(userId, {
    email: userTraits.email,
    first_name: userTraits.firstName,
    last_name: userTraits.lastName,
    display_name: userTraits.displayName,
    role: userTraits.role,
    company_name: userTraits.companyName,
    company_id: userTraits.companyId,
  })
}

export const trackAccountCreateConfirmed = (email: string) => {
  trackClick('Account Created', {}, { traits: { email } })
}

export const trackUserLogin = (userId: string, email: string) => {
  window.analytics.identify(userId, { email })
}

export const trackSignInConfirmed = (email: string) => {
  trackClick('Sign In', {}, { traits: { email } })
}

export const trackProductAction = (action: string, product: IProductSimple, quantity?: number) => {
  const pricing = getFormattedProductPrice(product)
  const lowestPrice = Math.min(
    ...Object.values(pricing).reduce<number[]>((acc, curr) => {
      acc.push(...curr.map((p) => p.price))
      return acc
    }, [])
  )
  const specsList = [
    { label: 'SKU', key: 'sku' },
    { label: 'Name', key: 'description' },
    { label: 'Width', key: 'width' },
    { label: 'Length', key: 'length' },
    { label: 'Height', key: 'height' },
    { label: 'cap Style', key: 'capStyle' },
    { label: 'Capacity', key: 'capacity' },
    { label: 'Color', key: 'color' },
    { label: 'Height UOM', key: 'heightUom' },
    { label: 'Label Panel Diameter', key: 'labelPanelDiameter' },
    { label: 'Label Panel Height', key: 'labelPanelHeight' },
    { label: 'Label Panel Length', key: 'labelPanelLength' },
    { label: 'Label Panel Shape', key: 'labelPanelShape' },
    { label: 'Label Panel Width', key: 'labelPanelWidth' },
    { label: 'Material Type', key: 'materialType' },
    { label: 'Neck Finish', key: 'neckFinish' },
    { label: 'Shape', key: 'shape' },
    { label: 'Weight', key: 'weight' },
  ]
  const properties: any = {
    product_id: product.properties?.sku,
    sku: product.properties?.sku,
    product_category: product.properties?.productFamily,
    name: product.properties?.description,
    price: lowestPrice,
  }

  if (quantity) {
    properties.quantity = quantity
  }
  specsList.forEach((spec) => {
    if (product.properties?.[spec.key]) {
      properties[spec.key] = product.properties[spec.key]
    }
  })
  trackClick(action, properties)
}
